
import React, { Component } from 'react';
import Header from './Header';
import MainSection from './MainSection';
import { Chart } from '../components/helpers';
import { createHorizontalBarChart} from '../components/dashboard/utils/chart_utils';
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import { Theme, Col, Loading, Row, LabeledSelect, TabBar, Tab, Popup } from '@commonsku/styles';
import { connect } from 'react-redux';
import { getOptions } from '../components/company-search/selectors';
import { createLoadClientList } from '../actions/client';
import { createSelector } from 'reselect';
import { getClientDropdown } from '../selectors/dropdowns';
import {updateSalesAnalyticsView,fetchSalesAnalyticsView,fetchAnalyticsReport, createLoadTeamList, createAllLoadStatuses} from '../actions';
import { dateStr} from '../utils';
import { createLoadAccountStatuses} from '../actions';
import { GlobalStyle } from './DashboardApp';
import DashboardPageTitle from '../components/dashboard/DashboardPageTitle';
import ProjectsTableWindowed from '../components/dashboard/ProjectsTableWindowed';
import SalesOrdersInProductionChart from './SalesOrdersInProdChart';
import InvoicesVsTargetChart from './InvoicesVsTargetChart';
import SalesActivityChart from './SalesActivityChart';
import CRMActivityChart from './CRMActivityChart';
import SalesAndMarginByClient from './SalesAndMarginByClientChart';
import { ANALYTICS_REPORT_TYPES, getCombinedTargetData, month_names } from './AnalyticsHelper';
import withReducers from '../store/withReducers';
import statusReducer from '../redux/status';
import notesReducer from '../redux/notes';
import { NewProjectPopupButton } from '../components/project/NewProjectPopup';

const getClientOptions = createSelector(getClientDropdown,
  values => values.filter(v => v.client_name).map(v => ({ value: v.client_id, label: v.client_name }))
);

class SalesAnalyticsApp extends Component{
state={
  selectedTab:'Sales',
  filters: {
    repType:{
      value: 'CLIENT', label: 'Client Rep'
    },
    repUser:{
      value: this.props.identity.user_id,label:'Logged user'
    },
    client:{
      value:'', label:'All'
    },
    clientStatus :{
      value:'', label:'All'
    },
    lastYear:false
  },
  showPopup:null
}

componentDidMount() {
  const { onLoadStatuses,onLoadClients, onLoadSalesData, onLoadTeams, onLoadAccountStatuses} = this.props;
  onLoadStatuses();
  onLoadClients();
  onLoadSalesData();
  onLoadTeams();
  onLoadAccountStatuses();
}

onClickInvoiceTargetChartColumn = (data,year_type = null)=> {
  const {fetchPopUpData} = this.props;
  const report_type = ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.type.sales;
  const route = ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.route;
  const yr = year_type === 'total_next_year' ? data.next_year : data.year;
  const title = data.month + ' ' + yr;
  const month_number = (new Date(`${data.month} 1 ${yr}`)).getMonth() + 1;
  const end_of_month = dateStr(new Date(yr, month_number, 1), 1);
  const start_of_month = dateStr(new Date(yr, month_number - 1, 1));
  fetchPopUpData(route, report_type, {
    start_stamp : start_of_month,
    end_stamp: end_of_month,
    filters: JSON.stringify(this.state.filters)
  });
  this.setState({
    showPopup : {
      type:'projects-list',
      title:ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.title + title,
      report_type:report_type,
    }
  });
}

onClickSalesActivityColumn = (data,order_type = null) => {
  const {fetchPopUpData} = this.props;
  const report_type = ANALYTICS_REPORT_TYPES.SALES_ACTIVITY.type.sales;
  const route = ANALYTICS_REPORT_TYPES.SALES_ACTIVITY.route;
  const title = data.time;
  const month_number = (new Date(`${data.month} 1 ${data.year}`)).getMonth() + 1;
  const end_of_month = dateStr(new Date(data.year, month_number, 1));
  const start_of_month =  dateStr(new Date(data.year, month_number - 1, 1));
  fetchPopUpData(route, report_type, {
    order_type: order_type,
    start_stamp: start_of_month,
    end_stamp:end_of_month,
    filters : JSON.stringify(this.state.filters)
  });
 this.setState({
   showPopup:{type: 'projects-list',
   title: ANALYTICS_REPORT_TYPES.SALES_ACTIVITY.title + title,
   report_type: report_type,
   order_type: order_type,}
 });
}

onClickClientSlice = (data) => {
  if (data.sliceGrouperOther === true) {
    return;
  }
  const {fetchPopUpData} = this.props;
  const {selectedTab,filters} = this.state;
  const report_type = selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.type.sales : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.type.sales;
  const route = selectedTab === 'Sales' ?  ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.route : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.route;
  const month_name = month_names[parseInt(data.month)-1];
  const title = data.client_name + " - " + month_name;
  const start_of_month = dateStr(new Date(data.year, data.month - 1,1 ));
  const end_of_month = dateStr(new Date(data.year, data.month, 1));
  const subTitle = selectedTab === 'Sales' ?  ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.title : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.title;
  fetchPopUpData(route,report_type, {
    client_id:data.client_id,
    start_stamp : start_of_month,
    end_stamp : end_of_month,
    filters : JSON.stringify(filters)
  });
  this.setState({
    showPopup: {
      type: 'projects-list',
      title: subTitle + title,
      report_type: report_type
    }
  });
}

onClickSalesOrdersInProductionChartColumn = (data,year_type =null) => {
  const {fetchPopUpData} = this.props;
  const report_type = ANALYTICS_REPORT_TYPES.SALES_ORDERS_IN_PRODUCTION.type.sales;
  const route = ANALYTICS_REPORT_TYPES.SALES_ORDERS_IN_PRODUCTION.route;
  const yr = year_type === 'total_next_year' ? data.next_year : data.year;
  const title = data.month + ' ' +  yr;
  const month_number = (new Date(`${data.month} 1 ${yr}`)).getMonth() + 1;
  const end_of_month = dateStr(new Date(yr, month_number, 1));
  const start_of_month =  dateStr(new Date(yr, month_number - 1, 1));
  fetchPopUpData(route, report_type, {
    start_stamp: start_of_month,
    end_stamp:end_of_month,
    filters : JSON.stringify(this.state.filters)
  });
  this.setState({
    showPopup: {
    type: 'projects-list',
    title: ANALYTICS_REPORT_TYPES.SALES_ORDERS_IN_PRODUCTION.title + title,
    report_type: report_type,
    },
});
}
onChangeFilter(value,filter) {
  const { onChangeFilterDrop} = this.props;
  if (value === null) {
    value = {label: 'All', value: ''};
  }
  this.setState({filters: {...this.state.filters,[filter]:value}},() => {
    onChangeFilterDrop(this.state.filters);
  });
}
renderPopup() {
  if (!this.state.showPopup) {
    return null;
  }
  return (<Popup
    title={this.state.showPopup.title}
    onClose={() => {
     this.setState({showPopup:false});
    }}
    className={`popup ${this.state.showPopup.type}-analytics-popup`}
  >   {(this.state.showPopup.type === 'projects-list' ?
  <ProjectsTableWindowed
    {...this.state.showPopup}
    onSave={() => this.setState({showPopup:false})}
   loadReportTotal={reportType =>  this.setState({reportType:reportType})}
  />
: null)}
    <br />
  </Popup>
  );}

render() {
    const {popups,filterOptions,sales_by_client_data,invoice_data,invoice_target_data, sales_orders_data,sales_orders_target_data,
    margin_by_client_data,sales_activity_data,sales_pipeline_data, shop_by_invoices_data, sales_data, identity} =this.props;
    const {filters,selectedTab} = this.state;
    const clientFilterAllOption = [{label: 'All', value: ''}];
    const shopIsScroll = shop_by_invoices_data && shop_by_invoices_data.length > 25 ? true : false;
    const margin_sales_chart_data = {
    margin_by_client_data: margin_by_client_data,
    sales_by_client_data: sales_by_client_data
    };
    const originalStyle = {
        top: 0,
        left: 0,
        zIndex: 100,
        width: '100%',
        minHeight: '100%',
        background: 'rgb(237, 242, 245)',
        position: 'absolute',
				paddingBottom: '2em'
      };
      const repTypeOptions = [
        { value: 'CLIENT', label: 'Client Rep'},
        { value: 'ORDER', label: 'Order Rep'},
      ];
      let tabs=[
        {
          label:'Sales by Client',
          key:'Sales',
        }];
      tabs.push({
        label:'Margin by Client',
        key:'Margin',
      });
    return (<div>
        <Theme>
        <GlobalStyle />
        <Header>
        <div className="columns">
          </div>
        </Header>
        <MainSection popups={popups} style={{background:'rgb(237, 242, 245)'}}>
          <div className="main-content csku-styles" style={{ ...originalStyle, top: this.state.top_margin, marginTop: 0 }}>
            <Row style={{ marginTop: 0, marginBottom: 20, }}>
              <Col xs sm={6} padded>
                {this.renderPopup()}
                <DashboardPageTitle title={"Sales Analytics"} />
              </Col>
              <Col xs
                sm={6}
                padded
                end={1}
              ><NewProjectPopupButton /></Col>
            </Row>
            <Row  style={{ placeContent: 'flex-start' }}>
              <Col padded xl={4} sm={6} xs={8}>
                <LabeledSelect
                  label="Rep Type"
                  name="repType"
                  options={repTypeOptions}
                  value={filters.repType}
                  onChange ={(e)=>this.onChangeFilter(e,'repType')}
                  className="csku-select-v3"
                />
              </Col>
              <Col padded xl={4} sm={6} xs={8}>
                <LabeledSelect
                  label="Client"
                  name="client"
                  value={filters.client}
                  options={clientFilterAllOption.concat(filterOptions.clients)}
                  onChange ={(e)=>this.onChangeFilter(e,'client')}
                  menuShouldScrollIntoView={false}
                  className="csku-select-v3"
                  isClearable = {!(filters.client.value === '')}
                />
              </Col>
              <Col padded xl={4} sm={6} xs={8}>
                <LabeledSelect
                  label="Client Status"
                  name="clientStatus"
                  value={filters.clientStatus}
                  options={filterOptions.statuses_all}
                  onChange={(e)=>this.onChangeFilter(e,'clientStatus')}
                  menuShouldScrollIntoView={false}
                  className="csku-select-v3"
                  isClearable = {!(filters.clientStatus.value === '')}
                />
              </Col>
            </Row>
            {Object.keys(sales_data).length  ?
            <>
              <Row style={{ placeContent: 'flex-start' }}>
                <SalesOrdersInProductionChart chart_data={filters.repType.value != 'ORDER' && identity.show_sales_order_targets == true
                ? sales_orders_target_data : sales_orders_data}
                onClickChartColumn = {this.onClickSalesOrdersInProductionChartColumn}/>
                <InvoicesVsTargetChart  chart_data={filters.repType.value != 'ORDER' && identity.show_invoice_targets == true ? invoice_target_data : invoice_data}
                 onClickChartColumn = {this.onClickInvoiceTargetChartColumn}
                />
              </Row>
              <Row style={{placeContent:'flex-start'}}>
                <WidgetCardBox  colSizes={{xs: 12, lg: 12}}  components={{
                Title: <WidgetTitle />
                }}>
                  <Col xs >
                    <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                    {tabs.map((tab, index) =>
                    <Tab
                    size={tabs.length}
                    selected={tab.key === this.state.selectedTab}
                    onClick={() => {this.setState({ selectedTab: tab.key });}}>
                    {tab.label}
                    </Tab>)}
                    </TabBar>
                    <SalesAndMarginByClient chart_data ={margin_sales_chart_data}
                    selectedTab = {selectedTab} onClickClientSlice = {this.onClickClientSlice}
                    />
                  </Col>
                </WidgetCardBox>
              </Row>
        <Row style={{placeContent:'flex-start',backgroundColor:'rgb(237, 242, 245)'}}>
          <CRMActivityChart chart_data={sales_activity_data} />
              <WidgetCardBox  colSizes={{xs: 12,sm:12, lg: 6}}components={{
              Title:<WidgetTitle startText={'Invoices by Shop'}/>
            }}>
            <Col xs>
              <Chart
              type="Bar"
              height="400px"
              width="100%"
              isExportable={false}
              data = {shop_by_invoices_data}
              data2 = {[
                {
                "shop_name": "Vandlay shop",
                "invoice_total": 350,
              },{
                "shop_name": "my shop",
                "invoice_total": 650,
              },{
              "shop_name": "Shop 12",
              "invoice_total": 700,
              }, {
                "shop_name": "Vandlay",
                "invoice_total": 850,
              },{
                "shop_name": "Ana Shops",
                "invoice_total": 990,
              }, {
                "shop_name": "Soft toys",
                "invoice_total": 1100,
              }, {
                "shop_name": "apparel shop",
                "invoice_total": 1350,
              },{
                "shop_name": "ovel shopping",
                "invoice_total": 1450,
              }, {
                "shop_name": "Client Shop",
                "invoice_total": 1600,
              }, {
                "shop_name": "Bonus Shop",
                "invoice_total": 1850,
              },
              {
                "shop_name": "Bo Shop",
                "invoice_total": 1850,
              },
              {
                "shop_name": "nus Shop",
                "invoice_total": 1850,
              },
              {
                "shop_name": "do Shop",
                "invoice_total": 1850,
              }
            ]}
              onChartReady={(chart,data)=>createHorizontalBarChart(chart,{
                data,
                isExportable:false,
                cursorLineX:false,
                cursorLineY:false,
                formatNumber:'#a',
                xAxes: [{
                  cursorTooltipEnabled:false,
                  category:'shop_name',
                  // title: 'Shops',
                  showGrid: false,
                  showAxis: true,
                  showTitle: false,
                  showLabel: false,
                  disableGridTemplate:true,
                  //fontSize:13,
                  //labelWidth: 80,
                  //labelTooltipText:`{shop_name}`,
                  //truncate:true,
                  maxZoomCount:25,
                }],
                yAxes: [{
                  cursorTooltipEnabled: false,
                  title: 'Invoices in last 60 days',
                  showGrid: false,
                  showAxis: true,
                  showTitle: true,
                  disableGridTemplate:true,
                  showLabel: true,
                  isScrollbarY:true
                }], series: [{
                  categoryX: 'shop_name',
                  valueY: 'invoice_total',
                  name: "Invoices",
                  valueYShow : "totalPercent",
                  tooltipText: `{shop_name}: {invoice_total}`,
                  strokeWidth: 0,
                  fillPropertyOpacity: 'opacity',
                  //fillDifferentColors: true,
                  fillColor:'#02C0DA',
                  stroke:'#02C0DA',
                  cornerRadius:8,
                  isScrollbarY:shopIsScroll,
                  //maxHeight:15,
                  //maxHeight2:70,
                  //CountHeightChange:6,
                  isInsideBarLabel:true,
                  insideBarLabelText:`{shop_name}`
                }],

              })}
              >

              </Chart>
              </Col>
              </WidgetCardBox>

              </Row>
            <div style={{display:'flex',  backgroundColor:'rgb(237, 242, 245)'}}>
              <SalesActivityChart chart_data={sales_pipeline_data} onClickChartColumn={this.onClickSalesActivityColumn}/>
            </div>
            </> : <>
            <Loading />
                <p style={{
                  padding: 10,
                  margin: 0,
                  textAlign: 'center',
                  color: '#00A0B6',
                  fontSize: '1.3rem',
                }}>Loading...</p>
            </>}
          </div>
        </MainSection>
        </Theme>
    </div>);
}

}
const mapStateToProps = (state, ownProps) => {
  const invoice_data = state.entities.salesAnalyticsData.invoice_results;
  const target_data = state.entities.salesAnalyticsData.sales_target_results;
  const  sales_orders_data = state.entities.salesAnalyticsData.in_production_results;
  return {
    identity: state.identity,
    filterOptions: {
      reps: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name)),
      reps_all: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name), false, [{ label: 'All', value: '' }]),
      statuses_all: getOptions(state.dropdowns.account_statuses, 'account_status_id', 'account_status_name', false, [{ label: 'All', value: '' }, { label: 'None', value: 'null' }]) ,
      clients:getClientOptions(state)

    },
    sales_data:state.entities.salesAnalyticsData,
    invoice_data :invoice_data,
    invoice_target_data : getCombinedTargetData(invoice_data,target_data),
    sales_orders_data:sales_orders_data,
    sales_orders_target_data: getCombinedTargetData(sales_orders_data,target_data),
    sales_by_client_data:  state.entities.salesAnalyticsData.client_sales_results,
    margin_by_client_data : state.entities.salesAnalyticsData.client_margin_results,
    sales_activity_data:state.entities.salesAnalyticsData.sales_activity_results,
    sales_pipeline_data: state.entities.salesAnalyticsData.sales_pipeline_results,
    shop_by_invoices_data:state.entities.salesAnalyticsData.shop_by_invoices_results

  };

};
const mapDispatchToProps = dispatch => ({
  onLoadAccountStatuses:  (company_type = 'CLIENT') => {
    dispatch(createLoadAccountStatuses(company_type));
  },
  onLoadStatuses:  () => {
    dispatch(createAllLoadStatuses());
  },
  onLoadClients: ()=>{
    dispatch(createLoadClientList({
      'order_by': 'latest_use',
      'order_dir': 'DESC',
    }));
  },
  onLoadTeams: ()=> {
    dispatch(createLoadTeamList({team_type: 'GROUP'}));
  },
    onChangeFilterDrop: (filters) => {
      dispatch(updateSalesAnalyticsView(filters));
    },
    onLoadSalesData: () =>{
      dispatch(fetchSalesAnalyticsView());
    },
    fetchPopUpData : (route,type,popup) =>{
      dispatch(fetchAnalyticsReport(route,type,popup));
    }
});

export default withReducers (connect(mapStateToProps,mapDispatchToProps)(SalesAnalyticsApp),{
  status: statusReducer,
  notes: notesReducer
});
